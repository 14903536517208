import React from 'react'
import { graphql } from 'gatsby'
import '../styles/common.css'
import NotFoundPage from '../pages/404'
import Content from './content'


export default ({ data }) => data === undefined
  ? <NotFoundPage />
  : <Content data={data.wordpressPost} />

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
